import { get, set } from "@/utils/vuex"
import Vue from 'vue';
const state = () => ({
  flags: {},
  loaded: false
});


const getters = {
  loaded: get('loaded'),
  flags: get('flags'),
  flag: (state) => (flag) => state.flags[flag],
};

const mutations = {
  setFlags(state, flags) {
		state.flags = flags;
	}, 
  setLoaded: set('loaded')
};

const actions = {
  load({dispatch}) 
  {
    //launchdarkly flags
    Vue.prototype.$ld.ldClient.on("ready", () => dispatch('setFlags'));
    Vue.prototype.$ld.ldClient.on("change", () => dispatch('setFlags'));
    Vue.prototype.$ld.ldClient.on("failed", (e) => {
      console.log('Failed to get Launch Darkly Flags...')
      console.log(e)
    });

  },

  setFlags({commit, rootGetters})
  {
    console.log('current flags: ', Vue.prototype.$ld.flags)
    const communitySettings = rootGetters['user/communitySettings']
    console.log('current community settings flags... ', communitySettings);
    commit('setFlags', {...Vue.prototype.$ld.flags, ...communitySettings })
	commit('setLoaded', true)
  }

};


export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions
};

