import { get, set } from "@/utils/vuex";
import vuetify from "@/plugins/vuetify";
import themes from "@/assets/themes.json";
import store from "..";
import Vue from "vue";
import _ from  "lodash";

const state = () => ({
	themes,
	logo: {
		light: "logo.svg",
		dark: "logoWhite.svg",
	},
	current: "K4Community Theme",
	dark: true,
});

const getters = {
	dark: get("dark"),
	current: (state) => state.themes.find((t) => t.name === state.current),
	logo: (state) => {
		let logo;
		if (typeof state.logo === "string") logo = state.logo;
		else logo = state.dark ? state.logo.dark : state.logo.light;
		return logo || "logo.svg";
	},
};

const mutations = {
	setCurrent(state, value) {
		state.current = value;
		const theme = state.themes.find((t) => t.name === value);

		if (!theme) return;

		vuetify.framework.theme.themes.light = theme.themes.light;
		vuetify.framework.theme.themes.dark = theme.themes.dark;
		state.logo = theme.logo;
	},
	setDark(state, value) {
		state.dark = value;
		vuetify.framework.theme.dark = value;
	},
	pushTheme(state, theme) {
		if (!theme || !theme.name) return;
		const idx = state.themes.findIndex((t) => t.name === theme.name);
		if (idx === -1) {
			state.themes.push(theme);
		} else {
			Vue.set(state.themes, idx, theme);
		}
	},

	setThemes: set('themes'),

	clearTheme(state) {
		state.themes = themes;
		this.commit("theme/setCurrent", "K4Community Theme");
		this.dispatch('settings/remove', 'themes');
		this.dispatch('settings/remove', 'theme');
	}
};

const actions = {
	init: async ({ commit, dispatch, rootGetters }) => {

		
		const isK4App = rootGetters['mobile/isK4App'];

		if(!isK4App)
		{
			//vuex isnt cached in web, so need to load from localstorage
			const json = rootGetters["settings/value"]("themes");
			try {
				const themes = JSON.parse(json);
				commit('setThemes', themes);
			} catch(e) {
				//no cached themes
			}
		}

		//restore user preferences

		const dark = rootGetters["settings/value"]("dark");
		commit("setDark", dark !== "false");

		let theme = rootGetters["settings/value"]("theme");

		if(theme === "default") {
			theme = null
		}

		if(theme && !_.isEmpty(theme)) {
			commit("setCurrent", theme);
		}

		
		if(rootGetters["flags/loaded"])
		{
			// set community theme if flags are loaded
			await dispatch("fetchCommunityThemes", !theme);
		}
		
		//watch for flag changes
		store.watch(
			() => rootGetters["flags/flags"],
			() => dispatch("fetchCommunityThemes", !theme)
		);
	},

	setDark: async ({ commit, dispatch }, value) => {
		await dispatch("settings/save", { name: "dark", value }, { root: true });
		commit("setDark", value);
	},

	setCurrent: async ({ commit, dispatch }, value) => {
		await dispatch("settings/save", { name: "theme", value }, { root: true });
		commit("setCurrent", value);
	},

	fetchCommunityThemes: async (
		{ commit, dispatch, rootGetters, state },
		override = true
	) => {
		const themeFile = rootGetters["flags/flag"]("communityCustomThemeFile");
		const themed = themeFile && !_.isEmpty(themeFile);

		let theme = rootGetters["settings/value"]("theme");

		if (themed) {
			try {
				const response = await fetch(themeFile);
				const themes = await response.json();
				themes.forEach((t) => {
					commit("pushTheme", t);
					if ((override && t.default) || theme == t.name) {
						dispatch("setCurrent", t.name);
					}
				});

				const isK4App = rootGetters['mobile/isK4App'];

				if(!isK4App)
				{
					//vuex isnt cached in web, so need to save to localstorage
					const json = JSON.stringify(state.themes);
					await dispatch("settings/save", { name: "themes", value: json }, { root: true });
				}

			} catch (e) {
				console.error(e);
			}
		} else {
			commit('clearTheme');
		}
	},
};

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
};
